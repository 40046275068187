import React from "react";
import {
  AppHeaderComponent,
  AppButtonComponent,
  TargetsApi,
} from "@unity/components";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";

export default function TargetImporter({ context, route, history }) {
  console.log("Here");
  const schema = [
    { name: "company_name", optional: false, label: "company_name" },
    { name: "address_line_1", optional: true, label: "Address line 1" },
    { name: "address_line_2", optional: true, label: "Address line 2" },
    { name: "address_line_3", optional: true, label: "Address line 3" },
    { name: "town", optional: true, label: "Town" },
    { name: "County", optional: true, label: "County" },
    { name: "postcode", optional: true, label: "PostCode" },
    { name: "region", optional: true, label: "Region" },
    { name: "contact_title", optional: true, label: "Contact Title" },
    { name: "contact_name", optional: true, label: "Contact Name" },
    { name: "contact_role", optional: true, label: "Contact Role" },
    { name: "contact_phone_cc", optional: true, label: "Contact Phone CC" },
    { name: "contact_phone", optional: true, label: "Contact Phone" },
    { name: "contact_mobile_cc", optional: true, label: "Contact Mobile CC" },
    { name: "contact_mobile", optional: true, label: "Contact Mobile" },
    { name: "contact_email", optional: true, label: "Contact Email" },
    { name: "sic_code", optional: true, label: "Sic Code" },
    {
      name: "sic_code_description",
      optional: true,
      label: "Sic Code Description",
    },
    { name: "industry", optional: true, label: "Industry" },
    {
      name: "number_of_employees",
      optional: true,
      label: "Number of Employees",
    },
    { name: "website", optional: true, label: "Website" },
    { name: "notes", optional: true, label: "Notes" },
    { name: "email_pref", optional: true, label: "Email Pref" },
    { name: "phone_pref", optional: true, label: "Phone Pref" },
    { name: "tag", optional: true, label: "Tag" },
    { name: "created_at", optional: true, label: "Created At" },
    { name: "updated_at", optional: true, label: "Updated At" },
    { name: "created_by", optional: true, label: "Created By" },
    { name: "created_by_name", optional: true, label: "Created By Name" },
  ];
  const splitInBatch = (arr, batchSize) => {
    return arr.reduce((accumulator, element, index) => {
      const batchIndex = Math.floor(index / batchSize);
      if (Array.isArray(accumulator[batchIndex])) {
        if (element.client_name.length > 0) {
          accumulator[batchIndex].push(element);
        }
      } else {
        accumulator.push([element]);
      }

      return accumulator;
    }, []);
  };
  return (
    <>
      <AppHeaderComponent
        name="importer"
        context={context}
        theme={context.theme}
      />
      <div style={{ paddingTop: 120 }}>
        <h5 style={{ textAlign: "center" }}>
          Please make sure your computer doesn't go to sleep or the import will
          stop.
        </h5>
        <Importer
          processChunk={async (rows) => {
            /*  const batch = await splitInBatch(rows, 100);
            console.log(batch); */

            const res = await TargetsApi.importerStore(rows);
          }}
          onComplete={() => {
            alert("Importing has finished");
          }}
        >
          {schema.map((item) => {
            return (
              <ImporterField
                name={item.name}
                label={item.label}
                optional={item.optional}
              />
            );
          })}
        </Importer>
      </div>
    </>
  );
}
