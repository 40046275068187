import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import ImporterIndex from "./pages";
import TargetImporter from "./modules/targets/targetImporter";
import PickerIndex from "./pages/picker-index";
export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {props.auth.access.campaigns["A"] &&
                      props.auth.access.campaigns["R"] && (
                        <Route
                          path="/importer/index"
                          exact
                          component={(route) => (
                            <PickerIndex
                              context={context}
                              route={route}
                              history={history}
                            />
                          )}
                        />
                      )}
                    <Route
                      path="/importer/targets"
                      exact
                      component={(route) => (
                        <TargetImporter
                          context={context}
                          route={route}
                          history={history}
                        />
                      )}
                    />

                    <Route
                      path="/importer/clients"
                      exact
                      component={(route) => (
                        <ImporterIndex
                          context={context}
                          route={route}
                          history={history}
                        />
                      )}
                    />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
