import React, { useEffect, useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import "react-csv-importer/dist/index.css";
import { ImporterApi } from "@unity/components";
//import { saveClient } from "../../../../api/clients";
import { CSVLink, CSVDownload } from "react-csv";
//import { saveTargets } from "../../../../api/targets";
import SmallLoader from "../common/SmallLoader";
export default function ClientImporter() {
  const [stage, setStage] = useState(1);
  const [site, setSiteState] = useState([]);
  const [importer, setImporter] = useState(false);
  //create the schema of which excel fields should map too
  const [type, setType] = useState(false);
  const [validation, setValidation] = useState([]);

  const schema = [
    { name: "sort_id", optional: false, label: "Sort ID" },
    { name: "client_name", optional: false, label: "Client Name" },
    { name: "client_status", optional: true, label: "Client Status" },
    { name: "client_tel", optional: true, label: "Client Telephone" },
    { name: "client_website", optional: true, label: "Client Website" },
    {
      name: "client_house_number",
      optional: true,
      label: "Client House Number",
    },
    { name: "client_town", optional: true, label: "Client Town" },
    { name: "client_postcode", optional: true, label: "Client Postcode" },
    { name: "client_first_line", optional: true, label: "Client First Line" },
    { name: "client_open", optional: true, label: "Client Opening Time" },
    { name: "client_closed", optional: true, label: "Client Closing Time" },
    { name: "client_special", optional: true, label: "Client Special Notes" },
    { name: "client_region", optional: true, label: "Client Region" },
    {
      name: "client_no_of_employees",
      optional: true,
      label: "Number of Employees",
    },
    { name: "customer_notes", optional: true, label: "Client Description" },
    { name: "client_industry", optional: true, label: "Client Industry" },
    {
      name: "client_annual_turnover",
      optional: true,
      label: "Client Annual Turnover",
    },
    {
      name: "indirect",
      optional: true,
      label: "Indirect (1 = true, 0 = false)",
    },
    { name: "direct", optional: true, label: "Direct (1 = true, 0 = false)" },
    { name: "online", optional: true, label: "Online (1 = true, 0 = false)" },
    {
      name: "corporate",
      optional: true,
      label: "Corporate (1 = true, 0 = false)",
    },
  ];

  var sites_headers = [
    "Sort ID",
    "System ID",
    "Company Name",
    "Site House / Building Number",
    "Site Street Address",
    "Site Town",
    "Site Postcode",
    "Site Opening Time ( FORMAT 08:00 )",
    "Site Closing Time ( FORMAT 17:00 )",
    "Site Notes",
    "NOTE *** TO ADD MORE THAN 1 SITE PLEASE COPY THE LINE AND INSERT IT ***",
  ];

  var integrity_headers = [
    [
      "Sort ID",
      "Client Name",
      "Client Status",
      "Client Telephone",
      "Client Website",
      "Client Town",
      "Client Postcode",
      "Client First Line",
      "Client Opening Time",
      "Client Closing Time",
      "Client Special Notes",
      "Client Region",
      "Number of Employees",
      "Client Description",
      "Client Industry",
      "Client Annual Turnover",
      "Account Number",
    ],
  ];

  useEffect(() => {}, []);

  const method = type ? ImporterApi.saveClients : ImporterApi.saveTargets;

  const [count, setCount] = useState(0);
  const splitInBatch = (arr, batchSize) => {
    return arr.reduce((accumulator, element, index) => {
      const batchIndex = Math.floor(index / batchSize);
      if (Array.isArray(accumulator[batchIndex])) {
        if (element.client_name.length > 0) {
          accumulator[batchIndex].push(element);
        } else {
          setValidation((prevState) => [
            ...prevState,
            [
              element.sort_key,
              element.client_name,
              element.client_status,
              element.client_tel,
              element.client_website,
              element.client_house_number,
              element.client_town,
              element.client_postcode,
              element.client_first_line,
              element.client_open,
              element.client_closed,
              element.client_special,
            ],
          ]);
        }
      } else {
        accumulator.push([element]);
      }

      return accumulator;
    }, []);
  };

  return (
    <>
      <div>
        <FormControlLabel
          control={<Switch checked={type} onChange={() => setType(!type)} />}
          label={type ? "Importing Clients" : "Importing Targets"}
        />
        <Importer
          onStart={() => setImporter(true)}
          processChunk={async (rows) => {
            const asyncFunctionsInBatches = await splitInBatch(rows, 10);

            await asyncFunctionsInBatches.reduce(
              async (previousBatch, currentBatch, index) => {
                await previousBatch;
                console.log(`Processing batch ${index}...`);
                const currentBatchPromises = currentBatch.map((asyncFunction) =>
                  method(asyncFunction)
                );
                const result = await Promise.all(currentBatchPromises);

                result.map((item) => {
                  setSiteState((prevState) => [
                    ...prevState,
                    [
                      item.data.sort_id,
                      item.data.client.ref,
                      item.data.client.client_name,
                    ],
                  ]);
                });
                setCount((prev) => prev + result.length);
              },
              Promise.resolve()
            );
          }}
          onComplete={() => {
            setStage(2);
          }}
        >
          {schema.map((item) => {
            return (
              <ImporterField
                name={item.name}
                label={item.label}
                optional={item.optional}
              />
            );
          })}
        </Importer>

        {importer && (
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              height: 500,
              position: "absolute",
              top: 320,
            }}
          >
            <p style={{ textAlign: "center", fontSize: 32, marginTop: 40 }}>
              <b>{count}</b> Records Completed
            </p>
            <p style={{ textAlign: "center", fontSize: 28 }}>
              {stage === 2 ? "Import Complete" : "Importing Please Wait..."}
            </p>
            {stage !== 2 && <SmallLoader />}

            {stage === 2 && (
              <CSVLink
                filename="csv_for_sites.csv"
                data={site}
                headers={sites_headers}
              >
                Download me
              </CSVLink>
            )}
            {validation.length > 0 && (
              <CSVLink
                filename="client_integrity.csv"
                headers={integrity_headers}
                data={validation}
              >
                Integrity Errors
              </CSVLink>
            )}
          </div>
        )}
      </div>
    </>
  );
}
