import React, { useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";

// include the widget CSS file whichever way your bundler supports it
import "react-csv-importer/dist/index.css";
import { CSVLink, CSVDownload } from "react-csv";
//import { storeSite } from "../../../../api/clients";
import SmallLoader from "../common/SmallLoader";

import { ImporterApi } from "@unity/components";

export default function SiteImporter() {
  const [stage, setStage] = useState(1);
  const [contacts, setContacts] = useState([]);
  const [validation, setValidation] = useState(false);
  const [importer, setImporter] = useState(false);

  //create the schema of which excel fields should map too
  var integrity_headers = [
    "Sort ID",
    "Contact ID",
    "Site House / Building Number",
    "Site Street Address",
    "Site Town",
    "Site Postcode",
    "Site Opening Time",
    "Site Closing Time",
    "Site Notes",
  ];
  const site_schema = [
    {
      name: "sort_id",
      optional: false,
      label: "Sort ID",
    },
    {
      name: "client_id",
      optional: false,
      label: "Contact ID",
    },
    {
      name: "site_house_number",
      optional: true,
      label: "Site House / Building Number",
    },
    { name: "site_first_line", optional: true, label: "Site Street Address" },
    { name: "site_town", optional: true, label: "Site Town" },
    { name: "site_postcode", optional: true, label: "Site Postcode" },
    {
      name: "site_open",
      optional: true,
      label: "Site Opening Time",
    },
    { name: "site_closed", optional: true, label: "Site Closing Time" },
    { name: "site_special", optional: true, label: "Site Notes" },
  ];

  var contacts_headers = [
    "System Contact ID",
    "System Site ID",
    "System Site Address",
    "Site Contact Name",
    "Site Contact Tel",
    "Site Contact Mob",
    "Site Contact Email",
    "Site Contact Role",
    "Site Contact Extension",
    "Site Contact Mob",
    "NOTE *** TO ADD MORE THAN 1 CONTACT PLEASE COPY THE LINE AND INSERT IT ***",
  ];
  const [count, setCount] = useState(0);
  const splitInBatch = (arr, batchSize) => {
    return arr.reduce((accumulator, element, index) => {
      const batchIndex = Math.floor(index / batchSize);
      if (Array.isArray(accumulator[batchIndex])) {
        if (element.client_id) {
          accumulator[batchIndex].push(element);
        } else {
          setValidation((prevState) => [
            ...prevState,
            [
              element.client_id,
              element.site_house_number,
              element.site_first_line,
              element.site_town,
              element.site_postcode,
              element.site_open,
              element.site_closed,
              element.site_special,
            ],
          ]);
        }
      } else {
        accumulator.push([element]);
      }

      return accumulator;
    }, []);
  };
  return (
    <>
      <div>
        <Importer
          onStart={() => setImporter(true)}
          processChunk={async (rows) => {
            const asyncFunctionsInBatches = await splitInBatch(rows, 10);

            await asyncFunctionsInBatches.reduce(
              async (previousBatch, currentBatch, index) => {
                await previousBatch;
                console.log(`Processing batch ${index}...`);
                const currentBatchPromises = currentBatch.map((asyncFunction) =>
                  ImporterApi.storeSite(asyncFunction)
                );
                const result = await Promise.all(currentBatchPromises);
                result.map((item) => {
                  setContacts((prevState) => [
                    ...prevState,
                    [
                      item.data.site.client_id,
                      item.data.site.site_id,
                      item.data.site.site_first_line,
                    ],
                  ]);
                });
                setCount((prev) => prev + result.length);
              },
              Promise.resolve()
            );
          }}
          onComplete={() => {
            setStage(2);
          }}
        >
          {site_schema.map((item) => {
            return (
              <ImporterField
                name={item.name}
                label={item.label}
                optional={item.optional}
              />
            );
          })}
        </Importer>

        {importer && (
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              height: 500,
              position: "absolute",
              top: 270,
            }}
          >
            <p style={{ textAlign: "center", fontSize: 32, marginTop: 40 }}>
              <b>{count}</b> Records Completed
            </p>
            <p style={{ textAlign: "center", fontSize: 28 }}>
              {stage === 2 ? "Import Complete" : "Importing Please Wait..."}
            </p>
            {stage !== 2 && <SmallLoader />}

            {stage === 2 && (
              <CSVLink
                headers={contacts_headers}
                filename="csv_for_contacts.csv"
                data={contacts}
              >
                Download me
              </CSVLink>
            )}
            {validation.length > 0 && (
              <CSVLink
                headers={integrity_headers}
                filename="sites_integrity.csv"
                data={validation}
              >
                Integrity Errors
              </CSVLink>
            )}
          </div>
        )}
      </div>
    </>
  );
}
