import React from "react";
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import "./importerStyles.css";

export default function PickerIndex({ context, route, history }) {
  return (
    <>
      <AppHeaderComponent
        name="importer"
        context={context}
        theme={context.theme}
      />

      <div className="importer-container">
        <div className="importer-buttons">
          <Link to={{ pathname: "/importer/clients" }}>
            <Button fullWidth variant="contained" size="large">
              Client
            </Button>
          </Link>
        </div>

        <div className="importer-buttons">
          <Link to={{ pathname: "/importer/targets" }}>
            <Button fullWidth variant="contained" size="large">
              Targets
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
