import React, { useContext } from "react";
import Loader from "react-loader-spinner";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckIcon from "@material-ui/icons/Check";
export default function SmallLoader({ color = false, message }) {
  if (!message) {
    return (
      <div style={{ textAlign: "center", marginTop: "12%" }}>
        <Loader
          type="TailSpin"
          //color={color ? color : context.theme.sidebar.background}
          height={80}
          width={80}
        />
      </div>
    );
  } else if (message.state === 0) {
    return (
      <div style={{ textAlign: "center", marginTop: "12%" }}>
        <ErrorOutlineIcon size={85} style={{ color: color, fontSize: 85 }} />
        <p style={{ fontSize: 20, fontWeight: "bold" }}>{message.msg}</p>
      </div>
    );
  } else if (message.state === 1) {
    return (
      <div style={{ textAlign: "center", marginTop: "12%" }}>
        <CheckIcon style={{ color: color, fontSize: 85 }} />
        <p style={{ fontSize: 20, fontWeight: "bold" }}>{message.msg}</p>
      </div>
    );
  } else if (message.state === 2) {
    return (
      <div style={{ textAlign: "center", marginTop: "12%" }}>
        <ErrorOutlineIcon size={85} style={{ color: color, fontSize: 85 }} />
        <p style={{ fontSize: 20, fontWeight: "bold" }}>{message.msg}</p>
      </div>
    );
  }
}
