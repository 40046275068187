import React, { useEffect, useState } from "react";

import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import ImportStepper from "../modules/ImportStepper";
import ClientImporter from "../modules/ClientImporter";
import SiteImporter from "../modules/SiteImporter";
import ContactsImporter from "../modules/ContactsImporter";

export default function ImporterIndex({ context, route, history }) {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <>
      <AppHeaderComponent
        name="importer"
        context={context}
        theme={context.theme}
      />

      <div style={{ paddingTop: 120 }}>
        <ImportStepper activeStep={activeStep} setActiveStep={setActiveStep} />
      </div>
      {activeStep === 0 && <ClientImporter />}
      {activeStep === 1 && <SiteImporter />}
      {activeStep === 2 && <ContactsImporter />}
    </>
  );
}
