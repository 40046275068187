import React, { useEffect, useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";

// include the widget CSS file whichever way your bundler supports it
import "react-csv-importer/dist/index.css";

import { CSVLink, CSVDownload } from "react-csv";
//import { storeContact, storeSite } from "../../../../api/clients";
import SmallLoader from "../common/SmallLoader";

import { ImporterApi } from "@unity/components";

export default function ContactsImporter() {
  const [stage, setStage] = useState(1);
  const [validation, setValidation] = useState([]);
  const [importer, setImporter] = useState(false);

  var integrity_headers = [
    "Client ID",
    "Site ID",
    "Contact Name",
    "Site Contact Tel",
    "Site Contact Mob",
    "Site Contact Email",
    "Site Contact Role",
    "Site Contact Extension Number",
    "Site Contact Mobile",
  ];

  const contact_schema = [
    {
      name: "client_id",
      optional: false,
      label: "System ID",
    },
    {
      name: "site_id",
      optional: false,
      label: "Site ID",
    },
    {
      name: "site_contact_name",
      optional: false,
      label: "Contact Name",
    },
    { name: "site_contact_tel", optional: true, label: "Site Contact Tel" },
    { name: "site_contact_mobile", optional: true, label: "Site Contact Mob" },
    { name: "site_contact_email", optional: true, label: "Site Contact Email" },
    {
      name: "site_contact_role",
      optional: true,
      label: "Site Contact Role",
    },
    {
      name: "site_contact_extension_number",
      optional: true,
      label: "Site Contact Extension Number",
    },
    { name: "site_contact_mob", optional: true, label: "Site Contact Mobile" },
  ];
  const [count, setCount] = useState(0);
  const splitInBatch = (arr, batchSize) => {
    return arr.reduce((accumulator, element, index) => {
      const batchIndex = Math.floor(index / batchSize);
      if (Array.isArray(accumulator[batchIndex])) {
        if (
          typeof element.site_contact_email === "undefined" ||
          (typeof element.site_contact_email === "string" &&
            element.site_contact_email.includes("@"))
        ) {
          accumulator[batchIndex].push(element);
        } else {
          setValidation((prevState) => [
            ...prevState,
            [
              element.client_id,
              element.site_id,
              element.site_contact_name,
              element.site_contact_tel,
              element.site_contact_mobile,
              element.site_contact_email,
              element.site_contact_role,
              element.site_contact_extension_number,
              element.site_contact_mob,
            ],
          ]);
        }
      } else {
        accumulator.push([element]);
      }

      return accumulator;
    }, []);
  };
  return (
    <>
      <div>
        <Importer
          onStart={() => setImporter(true)}
          processChunk={async (rows) => {
            const asyncFunctionsInBatches = await splitInBatch(rows, 10);

            await asyncFunctionsInBatches.reduce(
              async (previousBatch, currentBatch, index) => {
                await previousBatch;
                console.log(`Processing batch ${index}...`);
                const currentBatchPromises = currentBatch.map((asyncFunction) =>
                  ImporterApi.storeContact(asyncFunction)
                );
                const result = await Promise.all(currentBatchPromises);
                setCount((prev) => prev + result.length);
              },
              Promise.resolve()
            );
          }}
          onComplete={() => {
            setStage(2);
          }}
          onClose={() => {}}
        >
          {contact_schema.map((item) => {
            return (
              <ImporterField
                name={item.name}
                label={item.label}
                optional={item.optional}
              />
            );
          })}
        </Importer>
        {importer && (
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              height: 500,
              position: "absolute",
              top: 320,
            }}
          >
            <p style={{ textAlign: "center", fontSize: 32, marginTop: 40 }}>
              <b>{count}</b> Records Completed
            </p>
            <p style={{ textAlign: "center", fontSize: 28 }}>
              {stage === 2 ? "Import Complete" : "Importing Please Wait..."}
            </p>
            {stage !== 2 && <SmallLoader />}

            {validation.length > 0 && stage === 2 && (
              <CSVLink
                headers={integrity_headers}
                filename="contacts_integrity.csv"
                data={validation}
              >
                Integrity Errors
              </CSVLink>
            )}
          </div>
        )}
      </div>
    </>
  );
}
